import React from "react"
import { Link } from "gatsby"
import Image from "../components/image"
import Layout from "../components/layout"
// import SEO from "../components/seo"
import "../assets/sass/services.sass"

const Services = () => (

    <div className="info-page services">
        <div className="intro-page">
            <h1>Our Services</h1>
            <p>
                Kevin's Landscaping is a full service design and maintenance company. We offer many landscape management services to get your home ready for the next season.
            </p>
            <p>    
                Whether you need a gardening set up, assistance with your spring clean up, or reliable and expierened company to mow your grass,
                our team at Kevin's Landscaping is always ready to help.
            </p>
        </div>
        <div className="services-containers image">
            <img src="https://firebasestorage.googleapis.com/v0/b/kevins-fencing.appspot.com/o/side-home.jpg?alt=media&token=7c26df1f-ccb9-4882-9a0d-d7b5a91cdc43" alt="yard Image"/>
        </div>
        <div className="company-services services-containers">
            <h2>What We Do</h2>
            <div className="services-container">
                <div className="service-type">
                    <p>Landscaping</p>
                    <Link to="/services/landscaping">Read More</Link>
                </div>
                <div className="service-type">
                    <p>Snow plowing and salting</p>
                    <Link to="/services/snowplowing">Read More</Link>
                </div>
                <div className="service-type">
                    <p>Hardscaping</p>
                    <Link to="/services/hardscaping">Read More</Link>
                </div>
                <div className="service-type">
                    <p>Commercial Landscaping</p>
                    <Link to="/services/commercial">Read More</Link>
                </div>
            </div>
        </div>
    </div>

)

export default Services